<template>
  <div>
    <Invoice ref="InvoiceRef" />

    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deleteAppointment"
    />

    <v-row>
      <v-col cols="12">
        <div class="caption pasive--text text-right">
          {{ list.length }} pacientes
        </div>
      </v-col>
    </v-row>
    <v-data-table
      v-if="!$vuetify.breakpoint.smAndDown"
      v-model="selected"
      :headers="headers"
      :items="list"
      :items-per-page="list.length"
      :loading="loadingAppointments"
      :show-select="true"
      :no-results-text="'Cargando...'"
      item-key="code"
      loading-text="Cargando..."
      no-data-text="Sin datos..."
      class="mt-5"
      item-class="text-center"
      hide-default-footer
      disable-sort
    >
      <template v-slot:body="{ items, isSelected, select }">
        <draggable
          tag="tbody"
          :list="list"
          :move="checkPositionMove"
          @end="handleRowDragEnd"
        >
          <tr v-for="(item, index) in items" :key="index" style="cursor: grab">
            <td>
              <v-checkbox
                :input-value="isSelected(item)"
                style="margin: 0px; padding: 0px"
                @click="select(item, !isSelected(item))"
                hide-details
              >
              </v-checkbox>
            </td>
            <td class="text-center">{{ item.position }}</td>
            <td class="text-center">{{ item.code }}</td>
            <td>
              <Patient :patient="item.patient">
                {{ item.patient.firstName }} {{ item.patient.lastName }}
              </Patient>
            </td>
            <td>{{ item.reason }}</td>
            <td>{{ item.estimatedTime || "-" }}</td>
            <td class="text-center">
              <InvoiceBanner
                @show-create-form="handleClickPayment(item, 'create')"
                @show-info="handleClickPayment(item, 'showInfo')"
                :invoice="item ? item.invoices && item.invoices[0] : null"
                :clickable="true"
              />
            </td>
            <td class="text-center">
              <div class="d-flex justify-center my-3">
                <v-menu bottom offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-3" text icon>
                      <v-icon size="20px">mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="!(attending && attending.id === item.id)"
                      @click="handleClickEdit(item)"
                    >
                      <v-list-item-content class="font-weight-medium">
                        <div class="d-flex align-center">
                          <v-icon size="18px" class="mr-3"
                            >mdi-square-edit-outline</v-icon
                          >
                          <div class="subtitle-2 font-weight-regular">
                            Modificar
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="!(attending && attending.id === item.id)"
                      @click="showWarning(item)"
                    >
                      <v-list-item-content class="font-weight-medium">
                        <div class="d-flex align-center">
                          <v-icon size="18px" class="mr-3"> mdi-delete </v-icon>
                          <div class="subtitle-2 font-weight-regular">
                            Eliminar
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
    <div v-else>
      <v-card
        v-for="(item, index) in list"
        :key="index"
        class="pa-3 mt-5"
        style="border-radius: 8px"
        elevation="6"
      >
        <div
          class="d-flex justify-space-between align-center font-weight-regular subtitle-2"
        >
          <div>#{{ item.position }}</div>
          <div class="d-flex align-center">
            <div class="ml-1">
              {{ formatDate(item.date) }} <span>{{ item.estimatedTime }}</span>
            </div>
            <v-menu bottom offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text small icon>
                  <v-icon size="20px">mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="!(attending && attending.id === item.id)"
                  @click="handleClickEdit(item)"
                >
                  <v-list-item-content class="font-weight-medium">
                    <div class="d-flex align-center">
                      <v-icon size="18px" class="mr-3"
                        >mdi-square-edit-outline</v-icon
                      >
                      <div class="subtitle-2 font-weight-regular">
                        Modificar
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="!(attending && attending.id === item.id)"
                  @click="showWarning(item)"
                >
                  <v-list-item-content class="font-weight-medium">
                    <div class="d-flex align-center">
                      <v-icon size="18px" class="mr-3"> mdi-delete </v-icon>
                      <div class="subtitle-2 font-weight-regular">Eliminar</div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div class="body-1 my-2 font-weight-bold">
          {{ item.patient.firstName }} {{ item.patient.lastName }}
        </div>
        <div class="d-flex subtitle-2 font-weight-regular">
          <div>Motivo: {{ item.reason }}</div>
        </div>
        <div class="d-flex subtitle-2 font-weight-regular">
          <div>Código: {{ item.code }}</div>
        </div>
        <div class="d-flex subtitle-2 mt-1 font-weight-regular">
          <InvoiceBanner
            @show-create-form="handleClickPayment(item, 'create')"
            @show-info="handleClickPayment(item, 'showInfo')"
            :invoice="item ? item.invoices && item.invoices[0] : null"
            :clickable="true"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
import Patient from "@/components/Patient/Patient";
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
import Invoice from "../Payment/Invoice.vue";
import WarningDialog from "../WarningDialog.vue";
import InvoiceBanner from "../Payment/InvoiceBanner.vue";

export default {
  name: "AppointmentWaitingList",
  components: {
    draggable,
    Invoice,
    WarningDialog,
    InvoiceBanner,
    Patient,
  },
  props: ["headers", "list", "loading", "hideEdit"],
  data() {
    return {
      appointment: {},
      payment: null,
      selected: [],
      beforeDragList: [],
      deleting: false,
    };
  },
  computed: {
    ...mapGetters([
      "loadingAppointments",
      "currentUser",
      "todayAppointments",
      "attending",
    ]),
  },
  methods: {
    ...mapMutations(["setAlert", "setAppointments", "setTodayAppointments"]),
    showWarning(item) {
      Object.assign(this.appointment, item);
      this.$refs.WarningDialogRef.description = `¿Seguro que desea remover "${this.appointment.patient.firstName} ${this.appointment.patient.lastName}" de la lista de consultas?`;
      this.$refs.WarningDialogRef.show = true;
    },
    numberWithCommas(x) {
      if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async deleteAppointment() {
      try {
        this.deleting = true;

        const res = await axios.delete(
          `/api/appointment/${this.appointment.id}`
        );
        const { message, appointments } = res.data;

        this.$emit("appointment-deleted", {
          newAppointmentList: appointments,
          appointment: this.appointment,
        });

        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$refs.WarningDialogRef.show = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
      }
    },
    handleClickEdit(item) {
      this.$emit("clickEditRow", item);
    },
    handleClickPayment(appointment, action) {
      this.$refs.InvoiceRef.appointment = appointment;
      this.$refs.InvoiceRef.patient = appointment.patient;

      if (action === "create") {
        this.$refs.InvoiceRef.setToCreate();
      }

      if (action === "showInfo") {
        this.$refs.InvoiceRef.invoice = appointment.invoices[0];
        this.$refs.InvoiceRef.showInfo();
      }
    },
    resetRowsSelected() {
      this.selected = [];
    },
    checkPositionMove(evt) {
      this.beforeDragList = this.list;

      // can't change the position patient if is in a attending status(first of the list)
      const firstTurn =
        evt.draggedContext.futureIndex === 0 || evt.draggedContext.index === 0;
      if (this.attending && firstTurn) return false;
    },
    handleRowDragEnd() {
      const newList = this.list.map((appointment, index) => ({
        ...appointment,
        position: index + 1,
      }));
      this.$emit("position-change", { list: newList });
      this.handlePositionChange(newList);
    },
    handlePositionChange(newList) {
      axios
        .put("/api/appointments/changeTurns", {
          appointments: newList.map(({ id, position }) => ({ id, position })),
        })
        .catch((error) => {
          this.$emit("position-change-failed", { list: this.beforeDragList });
          console.log(error);
          this.setAlert({
            show: true,
            color: "error",
            icon: "mdi-alert-remove",
            text: error?.response?.data?.message || "Error al actulizar turnos",
          });
        });
    },
  },
  watch: {
    attending() {
      this.resetRowsSelected();
    },
    selected(value) {
      this.$emit("row-selected", value);
    },
  },
};
</script>
